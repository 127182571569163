<template>
	<n-layout>
		<div class="singer_page_main_layout">
			<div class="singer_logo_slot">
				<a href="https://www.diffsinger.com">
					<img width="54px" src="//cloud.zhibin.club/dfs/dslogo.svg?866">

				</a>
				<div class="slogan">
					嘿~朋友，欢迎您来生成歌手盘点信息，您的信息将会按照提交顺序审核盘点，请耐心等待。
				</div>
				<a href="https://up.ly93.cc/" target="_blank" rel="noopener noreferrer"
					style="color: blue;">有涉及到链接的填写，请先点击上传文件</a>

			</div>
			<div class="form_container">
				<n-form style="display: flex; flex-direction: column; gap: 20px; width: 60%; margin: 0 auto;">
					<div v-for="item in singer_template" v-bind:key="item.key">
						<label>{{ item.value }}</label>
						<n-input type="text" :placeholder="item.value" v-model:value="save_date[item.key]">
						</n-input>
					</div>
					<n-button @click="save_json(save_date)">保存盘点信息</n-button>
				</n-form>
			</div>
		</div>
	</n-layout>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import { darkTheme } from 'naive-ui'
import { useThemeVars } from 'naive-ui'
import { NButton, NLayout, NInput, NForm } from 'naive-ui'
import { Search32Regular } from '@vicons/ionicons5'
export default defineComponent({
	name: 'SingersPage',
	components: {
		NButton,
		NLayout,
		NForm,
		NInput,
	},
	data() {
		return {
			Search32Regular,
			darkTheme,
			theme: ref(null),
			themeVars: useThemeVars(),
			singer_template: [
				{
					'key': 'name',
					'value': '您的歌手名称'
				},
				{
					'key': 'image',
					'value': '立绘图片链接'
				},
				{
					'key': 'painter',
					'value': '立绘画师'
				},
				{
					'key': 'operator',
					'value': '版权所有方名称'
				},
				{
					'key': 'voice',
					'value': '中之人昵称/名称'
				},
				{
					'key': 'voice_sex',
					'value': '性别设定（男生、女生、无性别设定等）'
				},
				{
					'key': 'voice_lang',
					'value': '支持语言'
				},
				{
					'key': 'voice_color',
					'value': '音色代号'
				},
				{
					'key': 'voice_funcs',
					'value': '支持的功能'
				},
				{
					'key': 'demo_name',
					'value': 'DEMO歌曲名称'
				},
				{
					'key': 'demo_tuner',
					'value': '歌曲调校者'
				},
				{
					'key': 'sample',
					'value': 'DEMO文件链接'
				},
				{
					'key': 'background_color',
					'value': '希望展示的背景色（如#000000）'
				},
				{
					'key': 'title_color',
					'value': '希望展示的标题色（如#ffffff）'
				},
				{
					'key': 'text_color',
					'value': '希望展示的正文色（如#ffffff）'
				}

			],
			save_date: {
				"name": "",
				"image": "",
				"operator": "",
				"voice": "",
				"voice_sex": "",
				"voice_lang": "",
				"voice_color": "",
				"voice_funcs": "",
				"demo_name": "",
				"demo_tuner": "",
				"painter": "",
				"sample": "",
				"background_color": "",
				"text_color": "",
				"title_color": ""
			}
		}
	},
	methods: {
		save_json(json_content) {
			console.log(json_content);
			//检测json是否有空值
			for (var key in json_content) {
				if (json_content[key] == "") {
					alert("请填写完整信息！");
					return;
				}
				if (key == "background_color" || key == "text_color" || key == "title_color") {
					if (!json_content[key].startsWith("#")) {
						alert("请填写正确的颜色格式！");
						return;
					}
				}
			}


			//保存json文件到本地
			var blob = new Blob([JSON.stringify(json_content)], { type: "application/json" });
			var link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = json_content.name + ".json";
			link.click();
			alert("生成成功，请将生成后的文件发送给孙飒。");

		}
	},
	// mounted() {

	// }
})
</script>

<style>
.singer_page_main_layout {
	display: flex;
	width: 100vw;
	flex-direction: column;
	align-items: center;
	height: auto !important;
    background: #00000008;
	padding: 20px;
}

.singer_logo_slot {
	display: flex;
	width: 100%;
	height: 180px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 20px;
}

.slogan {
	display: flex;
	color: #00000060;
	font-size: 14px;
	font-weight: 600;
}

.form_container {
	display: flex;
	background: #fff;
	width: 50%;
	border-radius: 10px;
	border: #00000010 solid 1px;
	padding: 40px;
}
</style>